.overview {
    color: #383737;
    padding: 10%   ;
    text-align: center;
    background-color: #ffffff;
}

.background {
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0' gradientTransform='rotate(196,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%230FF'/%3E%3Cstop offset='1' stop-color='%23CF6'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%23F00'/%3E%3Cstop offset='1' stop-color='%23FC0'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='5.28'%3E%3Cpath transform='translate(-36.75 8.4) rotate(5.25 1409 581) scale(1.021)' d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='1.7600000000000002' transform='translate(-21 21) rotate(6.3 800 450) scale(1.011)' cx='500' cy='100' r='40'/%3E%3Cpath transform='translate(18.9 -63) rotate(63 401 736) scale(1.011)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='1.6'%3E%3Cpath transform='translate(126 -8.4) rotate(2.1 150 345) scale(0.979)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='3.5200000000000005' transform='translate(-84 -52.5) rotate(75.6 1089 759)' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='translate(-126 42) rotate(12.6 1400 132)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: relative;
}

.highlighted{
    color: #D90368;
}

.header {
    max-width: 1000px;
}

.header h4 {
    font-size: 1.9rem;
    margin: 0;
}

.header h5 {
    font-size: 1rem;
    color: #707070;
}

.supportingImages {
    display: flex;
    flex-direction: column;
    max-width: 1500px;
    margin: auto;
}

.supportingImages img{
    width: 80%;
    margin: auto;
    display: none;
    max-width: 350px;
}

.supportingImages img:first-child{
    display: inline-block;
}

@media (min-width: 768px) {

    .overview {
        padding: 4%   ;
    }

    .header {
        width: 75%;
        margin: 0 auto;
    }

    .header h4 {
        font-size: 1.9rem;
        margin: 0;
    }
    
    .header h5 {
        font-size: .8rem;
    }
    
    .supportingImages {
        display: flex;
        flex-direction: row;
    }
    
    .supportingImages img{
        width: 20%;
        margin: 5;
        margin-top: 5%;
        display: inline-block;
    }

}