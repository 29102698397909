.container {
    padding: 10%;
    color: #383737;
    text-align: left;
    background-color: #FFF4EE;
}

.header {
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 20%;
}


@media (min-width: 768px) {
    .container {
        padding: 5% 15%;
    }

    .header {
        margin-bottom: 5%;
        font-size: 1.9rem;
    }

    .profiles {
        display: flex;
    }

}