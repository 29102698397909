.donations {
    color: #383737;
    padding: 3%   ;
    text-align: center;
    background-color: white;
}

.header h4 {
    font-size: 1.4rem;
    margin: 0;
}

.header h5 {
    font-size: 1.1rem;
    color: #707070;
}

.header img {
    width: 60%;
}

.donationButton {
    margin-bottom: 5%;

    transition-timing-function: ease;
    transition: 0.3s;
    transform: scale(1);
}

.donationButton:hover {
    transition-timing-function: ease;
    transition: 0.3s;
    transform: scale(1.1);
}

@media (min-width: 768px) {

    .donations {
        max-width: 900px;
        margin: auto;
    }

    .header h4 {
        font-size: 1.8rem;
        margin: 0;
    }
    
    .header h5 {
        font-size: .8rem;
        margin-top: 0;
    }

    .header img {
        width: 25%;
    }

}