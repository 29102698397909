.background {
    padding: 3% 10%;
    /* background-color: #D90368; */
    color:#FFFBFC;
    text-align: left;
    background-color: #D90368;
}

.logo {
    display: block;
    margin: 5% auto 20% 0;
    text-align: center;
}

.logo img {
    width: 75%;
}

.focusPoint {
    display: flex;
    flex-direction: column;
} 

.gameboy  {
    text-align: center;
}

.gameboy img {
    width: 35%;
    max-width: 250px;
}

.heroTag {
    font-size: 1.3rem;
    margin: auto; 
    text-align: center;
    width: 75%;
}

.showLinks {
    width: 75%;
    display: flex;
    flex-direction: column;
    margin: auto;
    vertical-align: top;
    text-align: center;  
}


.podcastLogo {
    width: 100%;
    display: inline-block;
    max-width: 250px;
    margin: 2% 0;

    transition-timing-function: ease;
    transition: 0.3s;
    transform: translate(0, 10px);
}

.podcastLogo:hover {
    transition-timing-function: ease;
    transition: 0.3s;
    transform: translate(0, -10px);
}



@media (min-width: 768px) {

    .logo {
        display: block;
        margin: auto;
        margin-bottom: 5%;
    }

    .logo img {
        width: 40%;
    }

    .hero {
        padding: 2.5% 5%;
        /* background-color: #D90368; */
        color:#FFFBFC;
        text-align: left;
        background-color: #D90368;
    }
    
    
    .focusPoint {
        display: flex;
        flex-direction: row;
        margin: 2% auto;
        width: 80%;
        justify-content: center;
    } 
    
    .gameboy {
        margin: auto 0;
    }

    .gameboy img {
        width: 70%;
    }
    
    .heroTag {
        font-size: 2rem;
        margin: 0 0 5% 4%; 
        text-align: left;
        width: 70%;
        max-width: 500px;
    }
    
    .showLinks {
        width: 100%;
        display: flex;
        flex-direction: row;
        vertical-align: top;
        justify-content: center;
    }
    
    
    .podcastLogo {
        width: 90%;
        display: inline-block;
        margin: 5% 0;
    }
}