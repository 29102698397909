.container h4 {
    font-size: 1.6rem;
    padding: 10% 0 0 0;
    color: #FFFBFC;
    white-space: pre-line;
    margin: 0;
}

.treasureTrove {
    padding: 10% 6% 5%;
    background-color: #F7B85C;
    color: #383737;
}

.treasureTrove h4 {
    color: #383737;
    padding: 0 4%;
}

.treasureTrove h5 {
    color: #4E4C4C;
    font-size: 1.2rem;
    padding: 2.5% 10% 0;
}

.treasureTrove p {
    color: #4E4C4C;
    font-size: .8rem;
    padding: 0 10%;
}

.textContent {
    max-width: 1150px;
    margin: auto;
}

.vault{
    width: 80%;
}

@media (min-width: 768px) {
    .container h4 {
        font-size: 1.9rem;
        margin: 0;
        padding: 2% 0;
    }
    
    .container h5 {
        font-size: .8rem;
        padding: 0;
    }

    .textContent {
        max-width: 700px;
        margin: auto 0;
        text-align: left;
    }

    .treasureTrove {
        display: flex;
        padding: 4% 15%;
        justify-content: center;
        vertical-align: middle;
    }
    
    .vault{
        width: 20%;
        margin: auto;
    }
}