.container {
    background-color: #FFF;
    border-top: 1px solid gray;
    padding: .5%;
}

.footer {
    font-size: .5rem;
    text-align: center;
    color: #707070;
}